
@import 'src/assets/sass/fontawesome/fontawesome';
@import 'src/assets/sass/global/manifest';
@import 'src/assets/sass/utility_classes/manifest';
@import 'src/assets/sass/layout/manifest';
@import 'src/assets/sass/elements/manifest';

html {
  font-family: 'Century Gothic';
}

@import 'components/manifest';
